<template>
  <main>
    <Stack id="layout" direction="col" gap="none" align="center" justify="between" class="!min-h-screen">
      <div
        class="bg-[#000000] fixed top-none h-screen w-full z-20 opacity-50 cursor-pointer"
        v-if="overlay"
        @click="onOverlayClick"
      />
      <SimpleHeader title="secure" icon-name="padlock-unlocked" :subtitle="localSubtitle" />
      <Stack class="w-full !flex-grow">
        <slot />
      </Stack>
      <SimpleFooter class="mt-auto" />
    </Stack>
  </main>
</template>

<script lang="ts" setup>
import { SimpleLayoutProps } from "./types";
import SimpleHeader from "@/components/Global/SimpleHeader/SimpleHeader.vue";

const { title, description } = withDefaults(defineProps<SimpleLayoutProps>(), {
  description: "MKM Building Supplies, the UK's largest independent builders' merchant.",
});

// eslint-disable-next-line vue/no-dupe-keys
const { overlay, onOverlayClick } = useUIState();
const { selectedBranch } = useBranches();

const localSubtitle = computed(() => {
  const phone = selectedBranch?.value?.contacts?.phone;
  return phone ? `Need some help? ${phone}` : "";
});

useHead({
  title: `MKM - ${title}`,
  meta: [
    {
      name: "description",
      content: description,
    },
  ],
});
</script>
